import Vue from "vue";
import VueRouter from 'vue-router'
import home from '../view/home.vue'
import About from '../view/About.vue'
import Product from '../view/Product.vue'
import Service from '../view/Service.vue'
Vue.use(VueRouter)
const routes = [

  // {
  //   path:"/home",
  //   component:home,
  //   name:"home",

  // },
  {
    path: "/About",
    component: About,
    name: "About",

  },
  // {
  //   path:"/Product",
  //   component:Product,
  //   name:"Product",

  // },
  {
    path: "/Service",
    component: Service,
    name: "Service",

  },

  {
    path: '*',
    redirect: '/About'
  }


]

const router = new VueRouter({
  mode: 'hash',

  routes
})
export default router