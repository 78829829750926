<template>
  <div>
    <div class="home-top"></div>
    <div class="home-mid">
        <div class="mid-tip">
            <div class="tips" v-for="(item,index) in middata" :key="index" @click="go(item.url)">
                <div class="tips-all" >
                    <div class="tips-top">
                        <img :src="item.logo" alt="">
                        <div>{{item.tit}}<img :src="item.src" alt=""></div>
                    </div>
                    <div class="tips-text">
                        {{item.text}}
                    </div>
                    <div class="tips-bot">
                        <img src="" alt="">
                    </div>
                    <div class="tips-bot1">
                        收费软件
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="home-bot">
        <div class="bot-all">
            <div class="bot-tit">量化技术研究面对面</div>
            <div class="tit-text">与各界投资者面对面交流，可办讨论会、培训会等，欢迎各界投资机构在此提交预约联系我们</div>
        </div>
        <div class="bot-yuyue" @click="showform=true">
            <img src="../assets/img/bianji.png" alt="" style="position:relative;margin-right:5px;top:5px">
            <div style="position:relative;top:1px">在线预约</div>
        </div>
        <div class="bot-tel">咨询电话：400-811-3366</div>
    </div>
    <formss v-if="showform" @callback="callback" ref="fs"></formss>
  </div>
</template>

<script>
import formss from "../components/formss.vue";
export default {
    data(){
        return {
            showform:false,
            middata:[
                {
                    logo:require('../assets/img/T8.png'),
                    tit:'趋势策略量化软件',
                    text:'基于技术分析指标的量化交易一站式解决方案，麦语言积木式编程环境、历史数据、期货交易接口。',
                    url:'https://t8.wenhua.com.cn',
                    src:require('../assets/img/HOT.png'),
                },
                {
                    logo:require('../assets/img/T9.png'),
                    tit:'订单流策略量化软件',
                    text:'算法交易研发平台，支持股票/期货/期权的盘口订单流策略，以及智能拆单算法研发。',
                    url:'https://t9.wenhua.com.cn'
                },
                {
                    logo:require('../assets/img/T10.png'),
                    tit:'多因子策略量化软件',
                    text:'多因子策略研发平台，支持股票的多头、市场中性、指数增强，以及商品CTA策略研发。',
                    url:'https://t10.wenhua.com.cn',
                    src:require('../assets/img/New.png'),
                },
            ]
        }
    },
    methods:{
        callback(bool) {
        this.showform = bool;
        },
        go(url){
            window.open(url)
        }
    },
    components: {
    formss,
  },
}
</script>

<style scoped>
.home-top {
    background: url(../assets/img/Top_mp.jpg) no-repeat center;
    height: 496px;
}
.home-mid {
    background: url(../assets/img/banner2.jpg) no-repeat center;
    height: 278px;
}
.home-bot {
    overflow: hidden;
    background: url(../assets/img/bg-3.jpg) no-repeat center;
    height: 407px;
}
.mid-tip {
    display: flex;
    justify-content: space-between;
    width: 1260px;
    margin: 0 auto;
    position: relative;
    top: -75px;
}
.mid-tip .tips {
    width: 415px;
    height: 286px;
    background: url(../assets/img/bg-1.png) no-repeat center;
    position: relative;
    cursor: pointer;
    /* box-shadow: #d6d7db 3px 3px 6px 2px; */
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
}
.mid-tip .tips:hover{
    transform: scale(1.05);
}
.tips-all {
    margin: 60px 32px 0 32px;
}
.tips-top {
    display: flex;
    align-items: flex-end;
}
.tips-top div {
    margin-left: 12px;
    font-size: 20px;
    position: relative;
}
.tips-top div img{
    position: absolute;
    top: -5px;
    right:-25px;
}
.tips-text {
    color: #75767a;
    text-align: justify;
    margin-top: 25px;
}
.tips-bot {
    position: absolute;
    bottom: 25px;
    right: 32px;
    width: 50px;
    height: 50px;
    border-radius:50% ;
    background: url(../assets/img/jiantou.png) no-repeat center,white;
    cursor: pointer;
}
.tips-bot1{
    position: absolute;
    bottom: 37px;
    left: 32px;
    font-size: 16px;
    color: #b0afaf;
}
.bot-all {
    width: 1260px;
    margin: 0 auto;
    margin-top: 100px;
    text-align: center;
}
.bot-tit {
    font-size: 28px;
    color: white;
}
.tit-text {
    color: #d1d1cf;
    font-size: 16px;
    margin-top: 20px;
}
.bot-tel {
    color: #5876e2;
    font-size: 16px;
    margin-top: 27px;
}
.bot-yuyue {
    font-size: 16px;
    cursor: pointer;
    width: 156px;
    margin: 0 auto;
    margin-top: 65px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    display: flex;
    align-items: baseline;
    border-radius: 18px;
    background-image: linear-gradient(90deg,#88f4fe,#76cdff,#68aaff);
    background-image: -moz-linear-gradient(90deg,#88f4fe,#76cdff,#68aaff);
    justify-content: center;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
}
.bot-yuyue:hover {
    background-image: linear-gradient(90deg,#68aaff,#76cdff,#88f4fe);
    background-image: -moz-linear-gradient(90deg,#68aaff,#76cdff,#88f4fe);
}
</style>