<template>
  <div style="position:fixed;top:0;bottom:0;left:0;right:0;background:rgba(0,0,0,0.4);z-index:99999">
      <div class="aCon" >
      <div class="denote" style="letter-spacing:1px">
        预约讲解
        <div class="closeYY" @click="close"></div>
      </div>
      <div class="bCon">
        <p>
         预约金融工程师上门讲解软件， 该服务仅针对<span style="color:#ad2d0a">机构用户</span>，星格金融工程师可针对量化交易方案、策略回测、完善优化，算法交易等方面进行专业、系统的讲解，聆听你的建议。
        </p>
        <div class="subscribe sqzl">
          <div class="warp">
            <form id="form_list">
              <table class="table19">
                <tbody>
                                    <tr>
                    <td class="note-td0">所在城市：</td>
                    <td style="text-align:left">
                      <div class="group" ref="root0">
                        <div class="select text amp s_btn2" @click="proShow = !proShow" >
                          <span
                            class="turnbla"
                            style="color: rgb(0, 24, 44); margin-left: 8px"
                            >{{form.city}}</span
                          >
                          <s class="teg2"></s>
                        </div>
                        <ul class="list none" tag="select_city" v-show="proShow" ref="pro">
                          <li @click="selectLi(el)" v-for="(el,id) in City" :key="id">{{el}}</li>
                        </ul>
                      </div>
                      <input
                        type="hidden"
                        id="hidden"
                        name="city"
                        value="北京"
                      />
                    </td>
                  </tr>
                  <tr class="place"></tr>
                  <tr>
                    <td class="note-td0">机构名称：</td>
                    <td>
                      <div class="amp">
                        <input
                          type="text"
                          class="inp"
                          id="company"
                          name="company"
                          placeholder="请输入机构名称"
                          v-model="form.company"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr class="place"></tr>
                  <tr>
                    <td class="note-td0">姓名：</td>
                    <td>
                      <div class="amp">
                        <input
                          type="text"
                          class="inp"
                          id="applyname"
                          name="applyname"
                          placeholder="请输入真实姓名"
                          v-model="form.applyname"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr class="place"></tr>
                  <tr>
                    <td class="note-td0">联系电话：</td>
                    <td>
                      <div class="amp">
                        <input
                          type="text"
                          class="inp"
                          id="tel"
                          name="tel"
                          placeholder="请输入联系电话"
                          v-model="form.tel"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr class="place"></tr>
                  <tr>
                    <td class="note-td0">详细地址：</td>
                    <td>
                      <div class="amp" style="position:relative"> 
                        <input
                          type="text"
                          class="inp"
                          id="address"
                          name="address"
                          placeholder="请输入具体地址"
                          v-model="form.address"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style="color:#A3A19B;font-size:12px;padding-top:9px;padding-left:52px;text-align:left" colspan="2">
                      注：以上为必填项
                    </td>
                  </tr>
                  <!-- <tr class="place">
                    <td class="note-td0"></td>
                    <td class="zu" style="text-align:left;font-size:16px"><div>注：以上为必填项</div></td>
                  </tr> -->
                  <tr align="left">
                    <td class="note-td1" colspan="2">
                      <input
                        type="button"
                        id="submit"
                        class="submit"
                        value="提交"
                        @click="submitForm"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
data(){
    return{
        form:{
        city:'请选择城市',
        company:'',
        applyname:'',
        tel:'',
        address:'',
        type:5
      },
      City:['北京','上海','杭州','深圳','广州'],
      proShow:false,
    }
},
methods:{
    selectLi(name){
      this.form.city = name;
      this.proShow=false
    },
    submitForm(){
      if(this.form.company == ''){
        alert('请填写机构名称')
        return
      }
      if(this.form.applyname == ''){
        alert('请填写真实姓名')
        return
      }
      if(this.form.tel == ''){
        alert('请填写联系电话')
        return
      }
      if(this.form.city == '请选择城市'){
        alert('请选择城市')
        return
      }
      // if(this.form.address == ''){
      //   alert('请填写具体地址')
      //   return
      // }
      axios.post('http://172.16.8.203:809/api/AddApi/AddWh9Apply',this.form).then(res=>{
          // console.log(res);
          alert(res.data.message);
          this.close()
      })


    },
    close(){
      this.$emit('callback',false)
    },
    windowclick(e){
      if(this.$refs.root0.contains(e.target)){
        return
      }
      else{
        this.cityListShow=false
        this.proShow=false
      }
    },
},
created () {
  window.addEventListener('click',this.windowclick)
},
beforeDestroy(){
  window.removeEventListener('click',this.windowclick)
}
}
</script>

<style>
.aCon {
    width: 700px;
    background-color: #FBFBFB;
    position: fixed;
    top: 100px;
    left: 50%;
    margin-left: -350px;
    z-index: 999;
}
.denote {
    height: 32px;
    background: #d8e5f8;
    color: #012f6d;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    font-weight: 700;
}

.closeYY::before {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
}
.closeYY::before, .closeYY::after {
    content: '';
    top: 50%;
    left: 0;
    position: absolute;
    margin-top: -1px;
    background: #000;
    width: 70%;
    height: 2px;
    display: block;
}
.closeYY::after {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}
.closeYY {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 10px;
    top: 8px;
    cursor: pointer;
    overflow: hidden;
}
.bCon {
    padding: 40px 50px 60px 50px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.aCon p {
    /* text-indent: 2em; */
    font-size: 14px;
    line-height: 25px;
    margin: 0 0 20px 0;
    color: #00182c;
    text-align: justify;
}
.aCon p span {
    color: #ad2d0a;
}
.sqzl .warp {
    width: 100%;
    height: 100%;
}
.sqzl h2 {
    display: block;
    height: 36px;
    line-height: 36px;
    text-align: center;
    width: 100%;
    font-size: 16px;
    color: #00182c;
    font-weight: normal;
    position: relative;
    padding: 0 0 15px 0;
}
.sqzl .table19 {
    clear: both;
    margin: 0 auto;
}
.sqzl .note-td0 {
    text-align: right;
    color: #00182c;
    font-size: 14px;
    padding-right: 5px;
    vertical-align: middle;
}
.sqzl .group {
    display: inline-block;
    position: relative;
}
.sqzl .select {
    width: 159px;
    height: 30px;
    display: inline-block;
    text-align: left;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid #ababab;
    border-radius: 3px;
}
.sqzl .select span {
    font-size: 14px;
    line-height: 30px;
    margin-left: 12px;
    color: #757575;
}
.sqzl .select s {
    display: inline-block;
    float: right;
    margin-top: 12px;
    margin-right: 2px;
    width: 20px;
    height: 10px;
    overflow: hidden;
    background: url(https://wh9.wenhua.com.cn/static/img/arrbot.png) no-repeat center bottom;
}
.sqzl .list {
    width: 159px;
    background: #fff;
    border: 1px solid #ccc;
    border-top: none;
    max-height: 202px;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    top: 32px;
    left: 0;
    z-index: 1;
}
.sqzl .list li {
    height: 35px;
    text-align: center;
    line-height: 35px;
    cursor: pointer;
    font-size: 16px;
    color: #333;
}
.sqzl .table19 .place {
    height: 23px;
}
.sqzl .inp {
    width: 320px;
    height: 20px;
    padding: 5px 8px;
    border: 1px solid #ababab;
    border-radius: 3px;
    font-size: 14px;
}
.place .zu div {
    color: #A3A19B;
    white-space: nowrap;
    margin-top: 9px;
}
.sqzl .note-td1 {
    background: transparent;
}
.sqzl .note-td1 .submit {
    display: block;
    width: 104px;
    height: 34px;
    background-color: #3a5ddf;
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 1em;
    border: none;
    margin: 0 auto;
    margin-top: 40px;
    cursor: pointer;
    text-align: center;
    text-indent: 1em;
}
</style>