import { render, staticRenderFns } from "./formss.vue?vue&type=template&id=594c8d33&"
import script from "./formss.vue?vue&type=script&lang=js&"
export * from "./formss.vue?vue&type=script&lang=js&"
import style0 from "./formss.vue?vue&type=style&index=0&id=594c8d33&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports