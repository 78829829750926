<template>
  <div ref="rt" :class="{ height2: $route.name == 'About' }">
    <div class="xg-header">
      <div class="header-title">
        <div class="header-logo">
          <img src="./assets/img/logo_XG.png" alt="" />
          <!-- <div style="margin-left:6px">星格量化投资技术研究所</div> -->
        </div>
        <!-- <div class="header-router">
          <div class="rou">
            <router-link to="/home" class="fit">首页</router-link>
          </div>
          <div class="rous" style="position: relative" ref="rous">
            <div class="jiaa fit">软件产品</div>
            <div class="listpro" style="cursor: pointer">
              <div>
                <a href="https://t8.wenhua.com.cn" target="_blank"
                  >T8 - 趋势策略量化软件</a
                >
              </div>
              <div>
                <a href="https://t9.wenhua.com.cn" target="_blank"
                  >T9 - 订单流策略量化软件</a
                >
              </div>
              <div>
                <a href="https://t10.wenhua.com.cn" target="_blank"
                  >T10 - 多因子策略量化软件</a
                >
              </div>
            </div>
          </div>
          <div class="rou">
            <router-link to="/Service?idx=0" class="fit">服务</router-link>
            <div class="listpro rtnone" style="cursor: pointer">
              <div>
                <router-link to="/Service?idx=0">线上/电话服务</router-link>
              </div>
              <div>
                <router-link to="/Service?idx=1">Vip服务通道</router-link>
              </div>
            </div>
          </div>
          <div class="rou">
            <router-link to="/About" class="fit">关于</router-link>
          </div>
        </div> -->
      </div>
    </div>
    <router-view></router-view>
    <div class="xg-footer" :class="{ footer2: $route.name == 'About' }">
      ©版权所有 &nbsp;&nbsp;&nbsp;&nbsp;星格量化投资技术研究所(苏州)有限公司
      &nbsp;&nbsp;&nbsp;&nbsp; ICP备案号:
      <a href="https://beian.miit.gov.cn/" target="_blank"
        >苏ICP备2023013947号</a
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ifzsy: false,
    };
  },
  // watch:{
  //         $route:{
  //         handler(val,oldval){
  //             this.$nextTick(()=>{
  //                 console.log(val,oldval)

  //                 console.log(window.screen.height,this.$refs.rt.getBoundingClientRect().height)
  //                 if(window.screen.height<=this.$refs.rt.getBoundingClientRect().height){
  //                     this.ifzsy=false
  //                 }
  //                 else{
  //                      this.ifzsy=true
  //                 }
  //             })
  //         },
  // }
  // }
  // data(){
  //     return {
  //         active:false,
  //     }
  // },
  // methods:{
  //     showlisu(e){
  //         if(!!this.$refs.rous&&this.$refs.rous.contains(e.target)){
  //             this.active=!this.active
  //         }
  //         else{
  //             this.active=false
  //         }
  //     },
  // },
  created() {
    console.log(this.$route.name);
    window.addEventListener("resize", console.log(23));
  },
  // beforeDestroy(){
  //     window.removeEventListener('click',this.showlisu)
  // }
};
</script>

<style>
.height2 {
  height: 100%;
}
.xg-header {
  height: 75px;
  background: #f6f9fe;
  color: #505050;
  /* filter: drop-shadow(0 2px 4px #f6f9fe); */
  font-size: 16px;
}
.header-title {
  width: 1260px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.header-logo {
  display: flex;
  font-size: 23px;
  align-items: center;
  height: 100%;
}
.header-router {
  align-items: center;
  height: 100%;
  display: flex;
}
.header-router .rou {
  margin-left: 32px;
  margin-top: 11px;
  position: relative;
}
.header-router .rous {
  margin-left: 32px;
  cursor: pointer;
  margin-top: 11px;
}
.jiaa {
  text-decoration: none;
  color: #5f6062;
  display: block;
  height: 26px;
  border-bottom: 4px solid transparent;
}
/* .jiaa.active{
    border-bottom: 4px solid;
    -o-border-image: linear-gradient(90deg,#4060ff,#0dc4fd 99%) 2 2 2 2;
    border-image: linear-gradient(90deg,#4060ff,#0dc4fd 99%) 2 2 2 2;
} */
.header-router .rou a {
  text-decoration: none;
  color: #5f6062;
  display: block;
  height: 26px;
  border-bottom: 4px solid transparent;
}
.header-router .rou .fit:hover {
  color: #1342c2;
}
.header-router .rous .fit:hover {
  color: #1342c2;
}
.router-link-active {
  border-bottom: 4px solid;
  -o-border-image: linear-gradient(90deg, #4060ff, #0dc4fd 99%) 2 2 2 2;
  border-image: linear-gradient(90deg, #4060ff, #0dc4fd 99%) 2 2 2 2;
}
.xg-footer {
  background: #2d294a;
  color: #f2f1f7;
  text-align: center;
  height: 80px;
  line-height: 80px;
  font-size: 16px;
}
.xg-footer a {
  color: #f2f1f7;
  text-decoration: none;
}
.listpro {
  position: absolute;
  width: 200px;
  height: 0px;
  top: 46px;
  font-size: 15px;
  margin-top: -5px;
  background: white;
  overflow: hidden;
  transition: height 0.5s;
  -moz-transition: height 0.5s; /* Firefox 4 */
  -webkit-transition: height 0.5s; /* Safari and Chrome */
  -o-transition: height 0.5s; /* Opera */
  border: 1px solid transparent;
}
.rous:hover .listpro {
  height: 120px;
  border: 1px solid #c2c2c2;
  z-index: 999;
}
.rou:hover .listpro {
  height: 80px;
  border: 1px solid #c2c2c2;
  z-index: 999;
}
.listpro div {
  height: 40px;
  text-align: left;
  padding-left: 8px;
  line-height: 40px;
}
.listpro div a {
  text-decoration: none;
  color: #5f6062;
}
.listpro div a:visited {
  color: #5f6062;
}
.listpro div:hover {
  background: #4597e1;
}
.listpro div:hover a {
  color: white;
}
.rtnone .router-link-active {
  border: none;
}
.footer2 {
  position: absolute;
  width: 100%;
  bottom: 0;
}
</style>